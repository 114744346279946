export const integrateLocalSamplesCN = {
    'Initiate.Analysis.Title1': '选择',
    'Initiate.Analysis.Title3': '注释选项',
    'Initiate.Analysis.Title4': '细胞过滤选项',
    'Initiate.Analysis.Title5': '细胞质量调整',
    'Initiate.Analysis.Title6': '分析选项',
    'Initiate.Analysis.Title7': '选择数据集ID',
    'Initiate.Analysis.Title8': '编辑样本信息',

    'Initiate.Analysis.Button1': '提交分析',
    'Initiate.Analysis.Button2': '保存草稿',
    'Initiate.Analysis.Button3': '添加数据集ID',

    'Initiate.Analysis.Annotation.Options.Algorithm': '注释算法',
    'Initiate.Analysis.Annotation.Options.Algorithm.Tip(不翻译)': 'Cortal A, Martignetti L, Six E, Rausell A (2020). “Cell-ID: gene signature extraction and cell identity recognition at individual cell level.” bioRxiv. doi: 10.1101/2020.07.23.215525.',
    'Initiate.Analysis.Annotation.Options.Reference': '注释参考',
    'Initiate.Analysis.Annotation.Options.Species': '物种',
    'Initiate.Analysis.Annotation.Options.Refrence.Species': '参考物种',
    'Initiate.Analysis.Annotation.Options.Refrence.Species.Tip': '请根据基因相似性选择物种。若注释所选物种与样本物种不一致，自动注释将自动进行基因组同源转换并注释。此处同源转换不改变后续分析所用基因组。',
    'Initiate.Analysis.Annotation.Options.Tissue': '参考组织',

    'Initiate.Analysis.Cell.Filtering.Options.Gene': '表达基因范围',
    'Initiate.Analysis.Cell.Filtering.Options.UMI': '表达UMI范围',
    'Initiate.Analysis.Cell.Filtering.Options.Mitochondrial.Content': '线粒体含量',
    'Initiate.Analysis.Cell.Filtering.Options.Ribosomal.Content': '核糖体含量',
    'Initiate.Analysis.Cell.Filtering.Options.Singleron.Recommeded': '推荐参数',

    'Initiate.Analysis.Cell.Quality.Tip': '分析流程默认检测双胞率，勾选后将执行去除双胞。流程会自动按照去除双胞、去除背景噪音、去除细胞周期效应、去除批次效应的顺序执行。如果选择两个或更多步骤，则前一个步骤的输出将成为后一个步骤的输入。',
    'Initiate.Analysis.Ambient.Tips': '为了进行去除背景噪音流程，请确保单个样本的细胞数均大于100。',
    'Initiate.Analysis.Cell.Quality.Contamination.Removal': '背景噪音去除',
    'Initiate.Analysis.Cell.Quality.Doublet.Detection.And.Removal': '双胞移除',

    
    'Initiate.Analysis.Clustering.Resolution': '聚类分辨率',
    'Initiate.Analysis.Options.Scale.Large.Data.Tip': '通过仅对高变基因进行缩放，特别是在处理超过50万细胞的大数据集时，可以减少计算过程中的噪声干扰和处理时间，并提高计算稳定性。',
    'Initiate.Analysis.Options.Batch.Effect.Removal.Tip': '默认情况下，我们不建议对癌症样本进行批次效应去除，以防止去除肿瘤内的异质性。',

    'Initiate.Analysis.Choose.File1': '选择已上传的文件',
    'Initiate.Analysis.Choose.File2': '选择项目文件',
    'Initiate.Analysis.Annotation.Options.Type': '注释类型',
    'Initiate.Analysis.Select.Dataset.IDs.Tip': '暂只支持SynEcoSys数据库标准化数据集.',
    'Initiate.Analysis.Celescope.Tips': '*列表中不会显示未解析及未添加物种信息的项目文件。请确保您的项目文件已完成解析，并包含物种信息。',
    'Initiate.Analysis.Uploaded.Tips': '1、列表中不会显示未添加物种信息的文件，请确保您的文件已添加物种信息。',
    'Initiate.Analysis.Uploaded.Tips2': '2、若需将矩阵同源转化为人类或小鼠基因组进行后续分析，为提高准确性，建议使用Ensembl基因组注释的样本矩阵。',
    // 'Initiate.Analysis.Submit.Tip1': '请选择数据集ID',
    'Initiate.Analysis.Submit.Tip2': '请指定组织类型',
    'Initiate.Analysis.Submit.Tip3': '请选择样本',
    'Initiate.Analysis.Submit.Tip4': '请输入正确的物种',
    'Initiate.Analysis.Submit.Tip5': '请填写任务名称',
    'Initiate.Analysis.Sample.Info.Tips6': '一个样本的细胞数需大于30且小于100,000个',
    'Initiate.Analysis.Submit.Tip8': '缺少必要参数',
    'Initiate.Analysis.File.Header1': '名称',
    'Initiate.Analysis.File.Header2': '大小',
    'Initiate.Analysis.File.Header3': '格式',
    'Initiate.Analysis.File.Header4': '上传时间',
    'Initiate.Analysis.File.Header5': '项目名称',
    'Initiate.Analysis.File.Header6': 'SampleID',
    'Initiate.Analysis.File.Title': '工作区',

    'Initiate.Analysis.Placeholder': '文件夹名称',
    
    'Initiate.Analysis.Submit.Tip9': '请检查样本细胞数。您样本中细胞数不符合要求（大于30且小于100,000）。',
    'Initiate.Analysis.Submit.Tip10': '请输入样本ID',
    'Initiate.Analysis.Submit.Tip11': '样本ID已存在',
    'Initiate.Analysis.Submit.Tip12': '请输入列标题',
    'Initiate.Analysis.Submit.Tip13': '请输入样品名称',
    'Initiate.Analysis.Submit.Tip14': '请重新上传数据',
    'Initiate.Analysis.Submit.Tip16(不翻译)': 'Aran D, Looney AP, Liu L, Wu E, Fong V, Hsu A, Chak S, Naikawadi RP, Wolters PJ, Abate AR, Butte AJ, Bhattacharya M (2019)., “Reference-based analysis of lung single-cell sequencing reveals a transitional profibrotic macrophage.” Nat. Immunol., 20, 163-172. doi: 10.1038/s41590-018-0276-y.',
    'Initiate.Analysis.Submit.Tip17': '样品名称不得包含空格、特殊字符，且不得以数字开头。',
    'Initiate.Analysis.Submit.Tip18': '请检查所选样本的物种是否统一，不同物种之间无法进行分析。',

    'Initiate.Analysis.Cell.Filtering.Options.Tips': 'Singleron recommended选项：<br>表达基因范围：mingene=200, maxgene=每个样本的98%基因。<br>表达UMI范围：minUMI=0, maxUMI=每个样本的98% UMI。<br>线粒体含量：选择最接近过滤 5% 细胞的阈值（5、10、15、20、30、50）。',

    'Initiate.Analysis.SES.Dataset.Title': '*请访问 {btn1} (Browse by - Study)，查看数据集的SynEcoSys ID。使用数据集需要额外获得SynEcoSys数据库的 {btn2}。',
    'Initiate.Analysis.SES.Dataset.Tips': '仅支持带有SynEcoSys标准化人工注释标签的数据集',
    'Initiate.Analysis.Standardized.Tips': '保留SynEcoSys标准化人工注释的细胞标签',
    'Initiate.Analysis.Automatic.Tips': '重新自动注释',
    'Initiate.Remove.Cell.Cycle.Effects.Tips': '若物种为人或小鼠，分析流程默认检测周期效应，勾选后将执行去除细胞周期效应。其他物种无法计算。流程会自动按照去除双胞、去除背景噪音、去除细胞周期效应、去除批次效应的顺序执行。如果选择以上两个或更多步骤，则前一个步骤的输出将成为后一个步骤的输入。',
    'Initiate.Scale.Large.Data': '仅缩放高变基因',
    'Initiate.Remove.Cell.Cycle.Effects': '细胞周期效应移除',

    'Cell.Filtering.Data.Preprocessing.Start.Tips1': '细胞过滤评估需先进行数据预处理。',
    'Cell.Filtering.Data.Preprocessing.Start.Tips3': '预处理需花费一些时间，请确认是否要继续？',
    'Cell.Filtering.Data.Preprocessing.Start.Tips2': '提示：细胞数越多，预处理时间越长',
    'Cell.Filtering.Data.Preprocessing.Start.Failed.Tips1': '预处理失败，可尝试点击下方按钮重新提交。',
    'Cell.Filtering.Data.Preprocessing.Start.Retry.Btn': '重新提交',
    'Cell.Filtering.Data.Preprocessing.Start.Btn': '开始预处理',
    'Cell.Filtering.Data.Preprocessing.Running.Tips': '预处理任务正在后台运行，您可以安全离开此页面，稍后返回查看结果。',

    'Cell.Filtering.Evaluate.Tab': '细胞过滤评估',
    'Cell.Filtering.Evaluate.Skip.Tab': '跳过细胞过滤评估',
    'Cell.Filtering.Data.Before': '过滤前',
    'Cell.Filtering.Data.After': '过滤后',
    'Cell.Filtering.Start.Evaluate': '评估',
    'Cell.Filtering.Start.Evaluate.Tips': '点击评估查看过滤后的评估结果。',
    'Cell.Filtering.Delete.Tips': '确认返回上一步？预处理结果将不会保存。',
};
export const integrateLocalSamplesUS = {
    'Initiate.Analysis.Title1': 'Choose from',
    'Initiate.Analysis.Title3': 'Annotation options',
    'Initiate.Analysis.Title4': 'Cell filtering options',
    'Initiate.Analysis.Title5': 'Cell quality adjustments',
    'Initiate.Analysis.Title6': 'Analysis options',
    'Initiate.Analysis.Title7': 'Select dataset IDs',
    'Initiate.Analysis.Title8': 'Edit sample info',

    'Initiate.Analysis.Button1': 'Submit analysis',
    'Initiate.Analysis.Button2': 'Save as draft',
    'Initiate.Analysis.Button3': 'Add dataset ID',

    'Initiate.Analysis.Annotation.Options.Algorithm': 'Algorithm*',
    'Initiate.Analysis.Annotation.Options.Algorithm.Tip(不翻译)': 'Cortal A, Martignetti L, Six E, Rausell A (2020). “Cell-ID: gene signature extraction and cell identity recognition at individual cell level.” bioRxiv. doi: 10.1101/2020.07.23.215525.',
    'Initiate.Analysis.Annotation.Options.Reference': 'Reference*',
    'Initiate.Analysis.Annotation.Options.Species': 'Species',
    'Initiate.Analysis.Annotation.Options.Refrence.Species': 'Reference species',
    'Initiate.Analysis.Annotation.Options.Refrence.Species.Tip': 'Please select species based on gene similarity. If the selected species in annotation options differs from that of the sample species, automatic genome homology conversion and annotation will be performed. This homology conversion here does not alter the genome used for analysis.',
    'Initiate.Analysis.Annotation.Options.Tissue': 'Reference tissue',

    'Initiate.Analysis.Cell.Filtering.Options.Gene': 'Expressed gene range',
    'Initiate.Analysis.Cell.Filtering.Options.UMI': 'Expressed UMI range',
    'Initiate.Analysis.Cell.Filtering.Options.Mitochondrial.Content': 'Mitochondrial content',
    'Initiate.Analysis.Cell.Filtering.Options.Ribosomal.Content': 'Ribosomal content',
    'Initiate.Analysis.Cell.Filtering.Options.Singleron.Recommeded': 'Singleron Recommeded',

    'Initiate.Analysis.Cell.Quality.Tip': 'The pipeline defaults to detecting doublets, and checking the box will initiate the doublet removal. The pipeline executes the following procedures in the order of doublet removal, contamination removal, cell-cycle effect removal and batch effect removal. If you choose two or more procedures, output of the proceeding procedure will be the input of the latter procedure. Please be aware of this when examining the results.',
    'Initiate.Analysis.Ambient.Tips': 'To complete the ambient RNA removal process, the number of cells in each sample must be greater than 100.',
    'Initiate.Analysis.Cell.Quality.Contamination.Removal': 'Ambient RNA removal',
    'Initiate.Analysis.Cell.Quality.Doublet.Detection.And.Removal': 'Doublet removal',

    
    'Initiate.Analysis.Clustering.Resolution': 'Clustering resolution',
    'Initiate.Analysis.Options.Scale.Large.Data.Tip': 'By scaling only the highly variable genes, processing time and noise interference during computation can be reduced, while computational stability is enhanced, especially when handling large datasets of over 500,000 cells.',
    'Initiate.Analysis.Options.Batch.Effect.Removal.Tip': 'By default we do not recommend batch effect removal for cancer samples to prevent removal of the intra-tumoral heterogeneity.',

    'Initiate.Analysis.Choose.File1': 'Choose from uploaded files',
    'Initiate.Analysis.Choose.File2': 'Choose from project files',
    'Initiate.Analysis.Annotation.Options.Type': 'Annotation type',
    'Initiate.Analysis.Select.Dataset.IDs.Tip': 'Currently, the integration supports SynEcoSys standardized datasets only.',
    'Initiate.Analysis.Celescope.Tips': '*Files that are unparsed or lack species information will not be displayed in the list. Please ensure that your files are parsed and include species information.',
    'Initiate.Analysis.Uploaded.Tips': '1. Files lacking species information will not be displayed in the list. Please ensure that your files have species information.',
    'Initiate.Analysis.Uploaded.Tips2': '2. For homology conversion to the human or mouse genome for further analysis, it is recommended to select matrices annotated with the Ensembl genome annotation to improve accuracy.',
    // 'Initiate.Analysis.Submit.Tip1': 'Please select dataset ID',
    'Initiate.Analysis.Submit.Tip2': 'Please specify tissue type',
    'Initiate.Analysis.Submit.Tip3': 'Please select samples',
    'Initiate.Analysis.Submit.Tip4': 'Please enter the correct Species',
    'Initiate.Analysis.Submit.Tip5': 'Please fill in the job name',
    'Initiate.Analysis.Sample.Info.Tips6': 'The cell counts of one sample should be greater than 30 and less than 100,000',
    'Initiate.Analysis.Submit.Tip8': 'Required parameter missing',



    'Initiate.Analysis.File.Header1': 'File name',
    'Initiate.Analysis.File.Header2': 'File size',
    'Initiate.Analysis.File.Header3': 'Format',
    'Initiate.Analysis.File.Header4': 'Uploaded on',
    'Initiate.Analysis.File.Header5': 'Project name',
    'Initiate.Analysis.File.Header6': 'SampleID',
    'Initiate.Analysis.File.Title': 'Workspace',

    'Initiate.Analysis.Placeholder': 'Folder name',

    'Initiate.Analysis.Submit.Tip9': 'Please check the cell counts in your samples. The cell counts in your samples does not meet the  requirements (greater than 30 and less than 100,000).',
    'Initiate.Analysis.Submit.Tip10': 'Please enter the SampleID',
    'Initiate.Analysis.Submit.Tip11': 'SampleID exists',
    'Initiate.Analysis.Submit.Tip12': 'Please enter column headings',
    'Initiate.Analysis.Submit.Tip13': 'Please enter SampleID',
    'Initiate.Analysis.Submit.Tip14': 'Please re-initiate analysis from uploading data files in Data analysis portal > Import files',
    'Initiate.Analysis.Submit.Tip16': 'Aran D, Looney AP, Liu L, Wu E, Fong V, Hsu A, Chak S, Naikawadi RP, Wolters PJ, Abate AR, Butte AJ, Bhattacharya M (2019)., “Reference-based analysis of lung single-cell sequencing reveals a transitional profibrotic macrophage.” Nat. Immunol., 20, 163-172. doi: 10.1038/s41590-018-0276-y.',
    'Initiate.Analysis.Submit.Tip17': 'Sample names must not contain spaces, special characters, and should not begin with a number.',
    'Initiate.Analysis.Submit.Tip18': 'Please check whether the selected samples have consistent species. Analysis cannot be continued between different species.',

    'Initiate.Analysis.Cell.Filtering.Options.Tips': 'Singleron recommended options: <br>Expressed gene range: mingene=200, maxgene=98% genes of each sample. <br>Expressed UMI range: minUMI=0, maxUMI=98% UMI of each sample. <br>Mitochondrial content: the threshold (5, 10, 15, 20, 30, 50) that is closest to filtering 5% of the cells.',

    'Initiate.Analysis.SES.Dataset.Title': '*Please visit {btn1} (Browse by - Study) for SynEcoSys IDs of datasets. Accessing datasets requires an additional {btn2} to the SynEcoSys database.  ',
    'Initiate.Analysis.SES.Dataset.Tips': 'Only support datasets with SynEcoSys standardized annotation.',
    'Initiate.Analysis.Standardized.Tips': 'Retain cell labels with SynEcoSys standardized annotation',
    'Initiate.Analysis.Automatic.Tips': 'Re-automatic annotation',
    'Initiate.Remove.Cell.Cycle.Effects.Tips': 'If the species is Homo sapiens or Mus musculus, the pipeline defaults to detecting cell-cycle effects, and checking the box will initiate the cell-cycle effects removal. This calculation cannot be computed for other species. The pipeline executes the following procedures in the order of doublet removal, contamination removal, cell-cycle effect removal and batch effect removal. If you choose two or more procedures, output of the proceeding procedure will be the input of the latter procedure. Please be aware of this when examining the results',
    'Initiate.Scale.Large.Data': 'Only scale highly variable genes',
    'Initiate.Remove.Cell.Cycle.Effects': 'Remove cell-cycle effects',

    'Cell.Filtering.Data.Preprocessing.Start.Tips1': 'To preview cell filtering, data preprocessing is required.',
    'Cell.Filtering.Data.Preprocessing.Start.Tips3': ' It will take some time, please confirm if you want to continue?',
    'Cell.Filtering.Data.Preprocessing.Start.Tips2': 'Notice：The more cells, the longer the preprocessing time.',
    'Cell.Filtering.Data.Preprocessing.Start.Failed.Tips1': 'Preprocessing failed. You can try clicking the button below to resubmit the preprocessing task. ',
    'Cell.Filtering.Data.Preprocessing.Start.Retry.Btn': 'Retry preprocessing',
    'Cell.Filtering.Data.Preprocessing.Start.Btn': 'Start preprocessing',
    'Cell.Filtering.Data.Preprocessing.Running.Tips': 'The preprocessing task is running. You can safely leave this page and return later to check the results.',
    'Cell.Filtering.Evaluate.Tab': 'Cell filtering evaluation',
    'Cell.Filtering.Evaluate.Skip.Tab': 'Skip cell filtering evaluation',
    'Cell.Filtering.Data.Before': 'Before Filtering',
    'Cell.Filtering.Data.After': 'After Filtering',
    'Cell.Filtering.Start.Evaluate': 'Evaluate',
    'Cell.Filtering.Start.Evaluate.Tips': 'Click Evaluate to view the filtered evaluation results. ',
    'Cell.Filtering.Delete.Tips': 'Are you sure you want to go back? The preprocessing result will not be saved.',
};