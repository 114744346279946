
/**
 * 处理启动分析模块的数据
 */
const initiateAnalysisModule = {
	namespaced: true,
	state: {
		triggerInitiateAnalysisDataSave: 1, // 触发启动分析的动作
    triggerInitiateAnalysisData: {
			celeScopeFiles: [],
			cellFilteringTaskStatus: "",
			datasetId: "",
			datasets: [],
			groupId: "",
			id: 0,
			matrixFiles: [],
			metadataFile: {
				columns: [],
				tableData: [],
			},
			mitochondrialGeneSet: {
				fileName: "",
				geneSet: [],
				preSelected: true,
			},
			pageTag: "",
			param: {
				algorithm: "",
				annotationType: "",
				basedOnSingleSamples: [],
				batchName: "",
				batchRemoveMethod: "",
				cellFilteringEvaluation: "skip",
				cellQualityAdjustments: [],
				cluster: 0,
				contaminationState: "",
				doubletState: "",
				geneRange: [],
				geneState: "",
				jobName: "",
				mitochondrial: 0,
				mitochondrialState: "",
				numberOfHighlyVariableGenes: 0,
				pcValueNum: 0,
				pcValueState: "",
				reference: "",
				ribosomal: 0,
				species: "",
				tissue: "",
				umiRange: [],
				umiState: "",
			},
			ribosomalGeneSet: {
				fileName: "",
				geneSet: [],
				preSelected: true,
			},
			stepTag: '1',
		},  // 保存启动分析的数据
    editTaskRequireData: {  // 编辑任务所需数据
			datasetId: '',
			groupId: '',
			id: 0,
    },

		activeStep: '1', // 当前步骤
		cellFilteringTaskStatus: '', // 细胞过滤任务状态
		cellFilteringEvaluation: 'skip', // 细胞过滤评估
	},
	mutations: {
		changeTriggerInitiateAnalysisDataSaveM(state) {
			state.triggerInitiateAnalysisDataSave++;
		},

    changeTriggerInitiateAnalysisDataM(state, data) {
      state.triggerInitiateAnalysisData = data;
    },

    changeEditTaskRequireDataM(state, data) {
      state.editTaskRequireData = data;
    },

		changeActiveStepM(state, step) {
			state.activeStep = step;
			state.triggerInitiateAnalysisData.stepTag = step;
		},

		changeCellFilteringTaskStatusM(state, status) {
			state.cellFilteringTaskStatus = status;
			state.triggerInitiateAnalysisData.cellFilteringTaskStatus = status;
		},

		changeCellFilteringEvaluationM(state, evaluation) {
			state.cellFilteringEvaluation = evaluation;
			state.triggerInitiateAnalysisData.param.cellFilteringEvaluation = evaluation;
		},
	},
	actions: {
		
		changeTriggerInitiateAnalysisDataSaveA({ commit }) {
			commit('changeTriggerInitiateAnalysisDataSaveM');
		},

		changeTriggerInitiateAnalysisDataA({ commit }, data) {
			commit('changeTriggerInitiateAnalysisDataM', data);
		},

    changeEditTaskRequireDataA({ commit }, data) {
      commit('changeEditTaskRequireDataM', data);
    },

		// 改变当前步骤
		changeActiveStepA({ commit }, step) {
			commit('changeActiveStepM', step);
		},

		// 改变数据预处理任务状态
		changeCellFilteringTaskStatusA({ commit }, status) {
			commit('changeCellFilteringTaskStatusM', status);
		},

		// 改变细胞过滤评估状态
		changeCellFilteringEvaluationA({ commit }, evaluation) {
			commit('changeCellFilteringEvaluationM', evaluation);
		},
	},
};

export default initiateAnalysisModule;
