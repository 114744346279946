import {
  // i18n
  APP_LANGUAGE,
} from '@/store/mutation-types';
import { loadLanguageAsync } from '@/locales';
import { isCNSite } from "@/utils";

const state = {
  size: localStorage.getItem("size") || "medium",
  lang: isCNSite() ? 'zh-CN': 'en-US',
};

const mutations = {


  SET_SIZE: (state, size) => {
    state.size = size;
    localStorage.setItem("size", size);
  },
  [APP_LANGUAGE]: (state, lang, antd = {}) => {
    state.lang = lang;
  },
};

const actions = {
  setSize({ commit }, size) {
    commit("SET_SIZE", size);
  },

  setLang({ commit }, lang) {
    return new Promise((resolve, reject) => {
      commit(APP_LANGUAGE, lang);
      loadLanguageAsync(lang).then(() => {
        resolve();
      }).catch((e) => {
        reject(e);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
