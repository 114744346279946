
import enLocale from 'element-ui/lib/locale/lang/en';
import momentEU from 'moment/locale/eu';
import { AccountInfoUS } from '../lang_modules/accountInfo';
import { analysisInfoUS } from "../lang_modules/analysisInfo";
import { downloadPortalUS } from "@/locales/lang_modules/downloadPortal";
import { myMainAnalysesUS } from "@/locales/lang_modules/myAnalyses";
import { mainResourcesUS } from "@/locales/lang_modules/resources";
import { dataAnalysisPageUS } from "@/locales/lang_modules/dataAnalysisPage";
import { buttonTextUS} from "@/locales/lang_modules/buttonText";
import { integrateLocalSamplesUS } from '@/locales/lang_modules/integrateLocalSamples';
import { importDataUS } from "@/locales/lang_modules/importData";

import { cellAnnotationUS } from '../lang_modules/cellAnnotation';
import { menuListUS } from "@/locales/lang_modules/menuList";

import { cellQualityUS } from '../lang_modules/cellQuality';
import { requestedDownloadsUS } from "@/locales/lang_modules/requestedDownloads";
import { tfRegulationUS } from "@/locales/lang_modules/TFResulation";
import { userUS } from "@/locales/lang_modules/user";
import { tutorialsUS } from "@/locales/lang_modules/tutorials";
import { integrateUS } from "@/locales/lang_modules/integrate";
const components = {
  antLocale: enLocale,
  momentName: 'eu',
  momentLocale: momentEU,
};
// 公共操作,除true，false以外首字母大写
const commonOper = {
  'common.Input': 'Please Input',
  'common.Select': 'Please Select',
  'common.Required': 'Required',
  'common.Logout': 'Logout',
  'common.Email': 'Email',
  'common.Apply': 'Apply',
  'common.Search': 'search',
  'Common.Submit': 'Submit',
  "message.user.logOutTitle": 'Tips',
  'message.user.logOutMessage': 'Confirm to log out?',
  "message.user.logOutConfirm": 'Confirm',
  "message.user.logOutCancel": 'Cancel',
  "message.user.logOutExit": 'Exiting',
  "message.login.signInText": 'Welcome back',
  'message.subscriptionText': 'Please request a subscription, and get more access to explore CeleLens Cloud ',
  'message.login.expiredText': 'Login status has expired, please login again',
  'message.Undergoing.MaintenanceText': 'The website is currently undergoing maintenance',
  'message.Undergoing.Later': 'Please visit later.',

  'Common.SampleID': 'Sample ID',
  'Common.My.Data': 'My data',
};

const siderMenu = {
  'menu.lims.management1': 'Sample default parameter management',
};


export default {
  ...siderMenu,
  ...components,
  ...commonOper,
  ...AccountInfoUS,
  ...analysisInfoUS,
  ...downloadPortalUS,
  ...myMainAnalysesUS,
  ...mainResourcesUS,
  ...dataAnalysisPageUS,
  ...buttonTextUS,
  ...integrateLocalSamplesUS,
  ...cellAnnotationUS,
  ...importDataUS,
  ...menuListUS,
  ...cellQualityUS,
  ...requestedDownloadsUS,
  ...tfRegulationUS,
  ...userUS,
  ...tutorialsUS,
  ...integrateUS,
};
