<template>
  <div class="step-one-container">
        <div class="show-content">
          <h2> {{ $t('Tutorials.Step4.Title') }}</h2>
          <div v-if="lang === 'zh-CN'">
            <ul>
              <li>
                <img src="@/assets/tutorials/Initiate1.png" alt="">
                <div class="desc">
                  <h3>第1步：</h3>
                  <p>从菜单栏进入 <strong>启动分析-整合本地数据</strong> 板块。</p>
                  </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate2.png" alt="">
                <div class="desc">
                  <h3>第2步：</h3>
                  <p>在<strong>选择样本</strong>步骤，选择需要分析的样本，编辑样本信息。同时可选择过滤所需的线粒体和核糖体基因集。</p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate3.png" alt="">
                <div class="desc">
                  <h3>第3步：</h3>
                  <p>
                    在<strong>细胞评估</strong>步骤，选择是否进行质量评估，若需要质量评估则需要先进行预处理。
                  </p>
                  <h3>第4步：</h3>
                  <p>
                    预处理完成后，可调整过滤参数，比较过滤前后每个样本细胞的分布情况。
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate4.png" alt="">
                <div class="desc">
                  <h3>第5步：</h3>
                  <p>
                    在<strong>分析选项</strong>步骤，可选择注释参考选项、细胞质量调整选项和其他分析选项。
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate5.png" alt="">
                <div class="desc">
                  <h3>第6步：</h3>
                  <p>
                    在<strong>提交预览</strong>步骤，可在提交前检查待整合分析的样本信息及参数选项。
                  </p>
                  <h3>第7步：</h3>
                  <p>
                    提交分析，可在<img src="@/assets/tutorials/my_analyses_CN.png" alt=""> 的
                    <img src="@/assets/tutorials/cloud_CN.png" alt="">模块查看结果等。
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <ul>
              <li>
                <img src="@/assets/tutorials/Initiate1.png" alt="">
                <div class="desc">
                  <h3>Step 1:</h3>
                  <p>
                    Go to the <strong>Initiate Analysis - Integrate Local Data</strong> section from the top menu bar.
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate2.png" alt="">
                <div class="desc">
                  <h3>Step 2:</h3>
                  <p>
                    In the <strong>Select Samples</strong> step, select the samples for analysis and edit the sample groups. You can also choose to filter the desired mitochondrial and ribosomal gene sets.
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate3.png" alt="">
                <div class="desc">
                  <h3>Step 3:</h3>
                  <p>
                    In the <strong>Cell Filtering</strong> step, choose whether to perform cell quality evaluation. 
                    If quality evaluation is needed, preprocessing must be done first.
                  </p>
                  <h3>Step 4:</h3>
                  <p>
                    After preprocessing is complete, 
                    adjust the filtering parameters and compare the distribution of cells in each sample before and after filtering.
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate4.png" alt="">
                <div class="desc">
                  <h3>Step 5:</h3>
                  <p>
                    In the <strong>Analysis Options</strong> step, select annotation reference options, cell quality adjustment options, and other analysis settings.
                  </p>
                </div>
              </li>
              <li>
                <img src="@/assets/tutorials/Initiate5.png" alt="">
                <div class="desc">
                  <h3>Step 6:</h3>
                  <p>
                    In the <strong>Preview Submission</strong> step, review the sample information and parameter settings before submitting the integration analysis.
                  </p>
                  <h3>Step 7:</h3>
                  <p>
                    Submit the analysis. You can view the results in the <img src="@/assets/tutorials/my_analyses_EN.png" alt=""> -
                    <img src="@/assets/tutorials/cloud_EN.png" alt="">Module.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: "stepOne",
  components: {
  },
  computed: {
    lang() {
      return this.$store.getters.lang;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.step-one-container {
  .show-content {
    padding-bottom: 10px;
  }
  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
    margin-top: 30px;
  }
  ul {
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      margin-top: 25px;
      img {
        height: fit-content;
      }
      &:first-child {
        margin-top: 15px;
      }
      .desc {
        margin: 5px 10px 0 30px;
        h3 {
          font-weight: bold;
          font-size: 14px;
          color: $main-color;
          white-space: nowrap;
          &:nth-child(n+2) {
            margin-top: 20px;
          }
        }
        p {
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          line-height: 20px;

          img {
            margin: 0 10px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>