export const integrateCN = {
  'Integrate.Step1.Title': '选择样本',
  'Integrate.Step2.Title': '细胞过滤',
  'Integrate.Step3.Title': '分析选项',
  'Integrate.Step4.Title': '提交预览',
  'Integrate.Next': '下一步',
  'Integrate.Previous': '上一步',
  'Integrate.As.Draft': '保存草稿',
  'Initiate.Job.Name': '任务名称',
  'Integrate.Mitochondrial.Geneset.Title': '线粒体基因集',
  'Integrate.Ribosomal.Geneset.Title': '核糖体基因集',
  'Integrate.Geneset.Name': '基因集名称',
  'Integrate.Geneset.Content': '基因',
  'Integrate.Mitochondrial.Geneset.Radio1': '预选线粒体基因集',
  'Integrate.Mitochondrial.Geneset.Radio2': '点击上传线粒体基因集 (.csv)',
  'Integrate.Mitochondrial.Geneset.Radio1.Tips': '预选线粒体基因集包含以“MT-/mt-”前缀命名基因，适合大部分人、小鼠和大鼠基因组。若目标物种的线粒体基因不使用以上前缀，请自行上传合适的基因集进行过滤。',
  'Integrate.Ribosomal.Geneset.Radio1': '预选核糖体基因集',
  'Integrate.Ribosomal.Geneset.Radio2': '点击上传核糖体基因集 (.csv)',
  'Integrate.Ribosomal.Geneset.Radio1.Tips': '预选核糖体基因集包含以“Rps-/Rpl-/RPS-/RPL-”前缀命名基因，适合大部分人、小鼠和大鼠基因组。若目标物种的核糖体基因不使用以上前缀，请自行上传合适的基因集进行过滤。',
  'Integrate.Geneset.Radio2.Tips': '选择一个基因集文件，每行包含一个基因。不需要标题。',
};
export const integrateUS = {
  'Integrate.Step1.Title': 'Select Samples',
  'Integrate.Step2.Title': 'Cell Filtering',
  'Integrate.Step3.Title': 'Analysis Options',
  'Integrate.Step4.Title': 'Preview Submission',
  'Integrate.Next': 'Next step',
  'Integrate.Previous': 'Previous step',
  'Integrate.As.Draft': 'Save as a draft',
  'Initiate.Job.Name': 'Job name',
  'Integrate.Mitochondrial.Geneset.Title': 'Mitochondrial Geneset',
  'Integrate.Ribosomal.Geneset.Title': 'Ribosomal Geneset',
  'Integrate.Geneset.Name': 'Gene set name',
  'Integrate.Geneset.Content': 'Genes',
  'Integrate.Mitochondrial.Geneset.Radio1': 'Pre-selected mitochondrial gene set',
  'Integrate.Mitochondrial.Geneset.Radio2': 'click to upload a mitochondrial gene set (.csv)',
  'Integrate.Mitochondrial.Geneset.Radio1.Tips': 'The pre-selected mitochondrial gene set includes genes with the "MT-/mt-" prefix, which is suitable for most human, mouse, and rat genomes. If the mitochondrial genes of the target species do not use these prefix, please upload an appropriate gene set for filtering.',
  'Integrate.Ribosomal.Geneset.Radio1': 'Pre-selected ribosomal gene set',
  'Integrate.Ribosomal.Geneset.Radio2': 'click to upload a ribosomal gene set (.csv)',
  'Integrate.Ribosomal.Geneset.Radio1.Tips': 'The pre-selected ribosomal gene set includes genes with the “Rps-/Rpl-/RPS-/RPL-” prefix, which is suitable for most human, mouse, and rat genomes. If the ribosomal genes of the target species do not use these prefix, please upload an appropriate gene set for filtering.',
  'Integrate.Geneset.Radio2.Tips': 'Select a gene set file. Each row contains a single gene. NO HEADER. ',
};