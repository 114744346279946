import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import momentCN from 'moment/locale/zh-cn';
import { AccountInfoCN } from '../lang_modules/accountInfo';
import { analysisInfoCN } from "../lang_modules/analysisInfo";
import { downloadPortalCN } from "@/locales/lang_modules/downloadPortal";
import { myMainAnalysesCN } from "@/locales/lang_modules/myAnalyses";
import { mainResourcesCN } from "@/locales/lang_modules/resources";
import { dataAnalysisPageCN } from "@/locales/lang_modules/dataAnalysisPage";
import { buttonTextCN } from "@/locales/lang_modules/buttonText";
import { integrateLocalSamplesCN } from '@/locales/lang_modules/integrateLocalSamples';
import { importDataCN } from "@/locales/lang_modules/importData";

import { cellAnnotationCN } from '../lang_modules/cellAnnotation';
import { menuListCN } from "@/locales/lang_modules/menuList";

import { cellQualityCN } from '../lang_modules/cellQuality';
import { requestedDownloadsCN } from "@/locales/lang_modules/requestedDownloads";
import { tfRegulationCN } from "@/locales/lang_modules/TFResulation";
import { userCN } from "@/locales/lang_modules/user";
import { tutorialsCN } from "@/locales/lang_modules/tutorials";
import { integrateCN } from "@/locales/lang_modules/integrate";
const components = {
  antLocale: zhLocale,
  momentName: 'zh-cn',
  momentLocale: momentCN,
};


// 公共操作
const commonOper = {
  'common.Input': '请输入',
  'common.Select': '请选择',
  'common.Required': '必填项',
  'common.Logout': '退出',
  'common.Email': '邮箱',
  'common.Apply': '应用',
  'common.Search': '搜索',
  'Common.Submit': '提交',
  "message.user.logOutTitle": '提示',
  'message.user.logOutMessage': '此操作將登出，是否继续？',
  "message.user.logOutConfirm": '确定',
  "message.user.logOutCancel": '取消',
  "message.user.logOutExit": '退出中',
  "message.login.signInText": '欢迎',
  'message.subscriptionText': '请申请更高权限，以获得更多功能权限 ',
  'message.login.expiredText': '登录状态已过期，请重新登录',
  'message.Undergoing.MaintenanceText': '网站正在维护中', // 网站正在维护中，请稍后尝试。
  'message.Undergoing.Later': '请稍后尝试。',
  'Common.SampleID': '样本ID',

  'Common.My.Data': '我的数据',
};
// 侧边菜单
const siderMenu = {
  'menu.lims.management1': '样本默认参数管理',
};




export default {
  ...siderMenu,
  ...components,
  ...commonOper,
  ...AccountInfoCN,
  ...analysisInfoCN,
  ...downloadPortalCN,
  ...myMainAnalysesCN,
  ...mainResourcesCN,
  ...dataAnalysisPageCN,
  ...buttonTextCN,
  ...integrateLocalSamplesCN,
  ...cellAnnotationCN,
  ...importDataCN,
  ...menuListCN,
  ...cellQualityCN,
  ...requestedDownloadsCN,
  ...tfRegulationCN,
  ...userCN,
  ...tutorialsCN,
  ...integrateCN,
};
